import React, { Component } from "react";
import axios from "../../../axiosInstance";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham-dark.css";

import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";

import "bootstrap/dist/css/bootstrap.min.css";

import * as moment from "moment";

import RegistrationCodeUI from "./RegistrationCodeUI.jsx";
import EmailDomainsUI from "./EmailDomainsUI.jsx";

import { EXPIRATION_STATUS } from "./constants";

import { deepEqual } from "../../../utils";

class RegistrationCodesConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: AllCommunityModules,
      rowHeight: 40,
      headerHeight: 40,
      overlayNoRowsTemplate:
        '<div class="ag-overlay-loading-top text-center"><p>No Records Found </p><div class="loader"></div></div></div>',
      overlayLoadingTemplate:
        '<div class="ag-overlay-loading-top text-center mt-40"><p>Please wait while loading</p><div class="loader5"></div></div></div>',
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          width: 80,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
        },
        {
          headerName: "Registration Code",
          field: "registrationCode",
          flex: 0.5,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
        },
        {
          headerName: "Start Date",
          field: "startDate",
          width: 100,
          sortable: true,
          cellRenderer: (params) => {
            const data = params.data;
            return moment(data.startDate).format("MM/DD/YYYY");
          },
        },
        {
          headerName: "End Date",
          field: "endDate",
          width: 100,
          sortable: true,
          cellRenderer: (params) => {
            const data = params.data;
            return moment(data.endDate).format("MM/DD/YYYY");
          },
        },
        {
          headerName: "Partner",
          field: "partnerName",
          flex: 1,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
        },
        {
          headerName: "Spend Limit",
          field: "spendLimit",
          flex: 1,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
          cellRenderer: (params) => {
            const data = params.data;
            let spendLimit = "";

            try {
              spendLimit = parseInt(data.spendLimit);

              if (!isNaN(spendLimit)) {
                spendLimit = spendLimit.toLocaleString();
              } else {
                spendLimit = "";
              }
            } catch (err) {
              console.log(`Error parsing spendLimit: ${err}`);
            }

            if (spendLimit.length > 0) {
              return `$${spendLimit}`;
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Tags",
          field: "tags",
          flex: 1,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
        },
        {
          headerName: "Max Count",
          field: "maxRegistrationCount",
          flex: 1,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
        },
        {
          headerName: "Current Count",
          field: "currentRegistrationCount",
          flex: 1,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
        },
        {
          headerName: "",
          field: "icon",
          width: 80,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            const data = params.data;

            link.className = `updateSettingsBtn btn btn-primary`;
            link.innerHTML = " Edit ";

            if(data.status === "Expired" && data.partner.handle === "guest-events"){
              link.disabled = true;
            }

            link.addEventListener("click", (e) => {
              e.preventDefault();
              this.openForm(data);
            });

            return link;
          },
        },
        {
          headerName: "",
          field: "icon",
          width: 140,
          cellRenderer: (params) => {
            const link = document.createElement("button");
            const data = params.data;

            link.className = `updateSettingsBtn btn btn-primary`;
            link.innerHTML = " Email Domains ";

            link.addEventListener("click", (e) => {
              e.preventDefault();
              this.openEmailDomainsForm(data);
            });

            return link;
          },
        },
      ],
      defaultColDef: {
        domLayout: "autoHeight",
      },
      rowData: [],
      partners: [],
      registrationCodesLoading: false,
      partnersLoading: false,
      loading: false,
      statusError: false,
      statusText: "",
      expirationStatus: EXPIRATION_STATUS[0],
      showForm: false,
      registrationCodeTitle: "",
      registrationCode: {},
      updatedRegistrationCode: {},
      showEmailDomainsForm: false,
      emailDomains: [],
      updatedEmailDomains: [],
      emailDomainsList: [],
      emailDomainsUpdated: false,
      partnersLoaded: false,
    };
  }

  componentDidMount() {
    const expirationStatus = EXPIRATION_STATUS.find(
      (item) => item.active === true
    );

    this.setState({
      expirationStatus,
    });

    this.getRegistrationCodes(expirationStatus.active);
    this.getPartners();
  }

  getRegistrationCodes = async (active) => {
    let url = `/api/registrationCodes/list`;

    this.setState({
      registrationCodesLoading: true,
    });

    if (active !== null) {
      url = `${url}?active=${active}`;
    }

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const { status, msg } = data;
        let list = data.list;

        if (!list || list.length === 0) {
          this.gridApi.setRowData([]);

          this.setState({
            rowData: [],
            statusError: !status,
            statusText: msg,
          });

          return;
        }

        list = list.map((item) => {
          item.partnerName = item.partner.name;

          if (item.emailDomains) {
            item.emailDomainsList = item.emailDomains.map((item) => {
              return {
                domain: item,
              };
            });
          } else {
            item.emailDomainsList = [];
          }

          return item;
        });

        this.gridApi.setRowData(list);

        this.setState({
          rowData: list,
          statusError: false,
          statusText: msg,
        });
      } else {
        this.gridApi.showNoRowsOverlay();
        this.gridApi.setRowData([]);

        this.setState({
          rowData: [],
          statusError: false,
          statusText: `No registration codes found.`,
        });
      }
    } catch (err) {
      console.log(err);

      this.gridApi.showNoRowsOverlay();
      this.gridApi.setRowData([]);

      this.setState({
        rowData: [],
        statusError: true,
        statusText: `Error getting registration codes: ${err}`,
      });
    }

    this.setState({
      registrationCodesLoading: false,
    });

    if (!this.state.partnersLoading) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: true,
      });
    }
  };

  getPartners = async () => {
    const url = `/api/registrationCodes/partners`;

    this.setState({
      partnersLoading: true,
    });

    try {
      const response = await axios.get(url);

      if (response && response.status === 200 && response.data) {
        const data = response.data;
        const list = data.list;

        if (list && list.length > 0) {
          this.setState({
            partners: list,
            partnersLoaded: true,
          });
        } else {
          this.setState({
            partners: [],
          });
        }
      } else {
        this.setState({
          partners: [],
        });
      }
    } catch (err) {
      console.log(err);

      this.setState({
        partners: [],
        statusError: true,
        statusText: `Error getting partners list: ${err}`,
      });
    }

    this.setState({
      partnersLoading: false,
    });

    if (!this.state.registrationCodesLoading) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        loading: true,
      });
    }
  };

  expirationStatusChanged = async (item) => {
    let status = "";

    if (item.active) {
      status = "active";
    } else if (!item.active) {
      status = "inactive";
    } else {
      status = "all";
    }

    this.setState({
      expirationStatus: item,
      statusError: false,
      statusText: `Loading ${status} registration codes`,
    });

    this.getRegistrationCodes(item.active);
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.gridApi.sizeColumnsToFit();
  };

  // Edit

  openForm = async (item) => {
    if (item) {
      item.startDate = moment(item.startDate).format("YYYY-MM-DD");
      item.endDate = moment(item.endDate).format("YYYY-MM-DD");
    }

    let data = JSON.parse(JSON.stringify(item))
    if(data.status){
      delete data.status
    }

    this.setState({
      statusError: false,
      statusText: "",
      registrationCode: item === null ? {} : item,
      updatedRegistrationCode:
        item === null ? {} : data,
      showForm: true,
      registrationCodeTitle:
        item === null
          ? "Add new Registration Code"
          : "Update Registration Code",
    });
  };

  closeForm = async () => {
    this.setState({
      showForm: false,
      registrationCodeTitle: "",
    });
  };

  validate = () => {
    const data = this.state.updatedRegistrationCode;

    if (!data.registrationCode || data.registrationCode.length === 0) {
      return true;
    } else if (!data.startDate || data.startDate.length === 0) {
      return true;
    } else if (!data.endDate || data.endDate.length === 0) {
      return true;
    } else if (data.startDate >= data.endDate) {
      return true;
    } else {
      return deepEqual(
        this.state.registrationCode,
        this.state.updatedRegistrationCode
      );
    }
  };

  saveForm = async () => {
    this.setState({
      showForm: false,
    });

    const item = this.state.updatedRegistrationCode;
    item.startDate = moment(item.startDate).format("MM/DD/YYYY");
    item.endDate = moment(item.endDate).format("MM/DD/YYYY");

    delete item.emailDomainsList;
    delete item.currentRegistrationCount;

    if (
      item.tags !== undefined &&
      item.tags !== null &&
      item.tags.length === 0
    ) {
      delete item.tags;
    }

    if (item.id === undefined) {
      const response = await this.createRegistrationCode(item);

      if (response) {
        response.partnerName = response.partner.name;

        if (response.emailDomains) {
          response.emailDomainsList = response.emailDomains.map((item) => {
            return {
              domain: item,
            };
          });
        } else {
          response.emailDomainsList = [];
        }

        const data = JSON.parse(JSON.stringify(this.state.rowData));
        data.unshift(response);
        console.log({ data });

        this.gridApi.setRowData(data);

        this.setState({
          rowData: data,
        });
      }
    } else {
      delete item.emailDomains;
      const response = await this.updateRegistrationCode(item);

      if (response) {
        response.partnerName = response.partner.name;

        const data = JSON.parse(JSON.stringify(this.state.rowData));
        const index = data.findIndex((itm) => itm.id === item.id);
        data[index] = response;

        this.gridApi.setRowData(data);

        this.setState({
          rowData: data,
        });
      }
    }
  };

  createRegistrationCode = async (data) => {
    const url = `/api/registrationCodes/code`;

    let response;

    try {
      // RM stringify
      response = await axios.post(url, data);

      if (response.status === 200 || response.status === 400) {
        const { status, msg, data: registrationCode } = response.data;

        this.setState({
          statusError: !status,
          statusText: msg,
        });

        return registrationCode;
      }
    } catch (err) {
      console.log(err);

      const msg = err.response?.data?.errMsg;

      this.setState({
        statusError: true,
        statusText: msg ? msg : `Error creating registration code: ${err}`,
      });
    }

    return null;
  };

  updateRegistrationCode = async (data) => {
    const url = `/api/registrationCodes/code/${data.id}`;

    if (data.partnerName) {
      delete data.partnerName;
    }

    if (!isNaN(data.spendLimit)) {
      data.spendLimit = parseInt(data.spendLimit);
    } else {
      data.spendLimit = null;
    }

    try {
      const response = await axios.patch(url, data);

      if (response.status === 200 || response.status === 400) {
        const { status, msg, data: registrationCode } = response.data;

        this.setState({
          statusError: !status,
          statusText: msg,
        });

        return registrationCode;
      }
    } catch (err) {
      console.log(err);

      const msg = err.response?.data?.errMsg;

      this.setState({
        statusError: true,
        statusText: msg ? msg : `Error updating registration code: ${err}`,
      });
    }

    return null;
  };

  registrationCodeChanged = async (data) => {
    this.setState({
      updatedRegistrationCode: data,
    });
  };

  // Email domains

  openEmailDomainsForm = async (item) => {
    let updatedEmailDomains = [];
    
    if (item) {
      updatedEmailDomains = [...item.emailDomains];
    }
    let data = item === null ? {} : JSON.parse(JSON.stringify(item));
    if(data.status){
      delete data.status;
    }

    this.setState({
      statusError: false,
      statusText: "",
      registrationCode: data,
      showEmailDomainsForm: true,
      updatedEmailDomains,
      emailDomainsList: data.emailDomainsList,
    });
  };

  closeEmailDomainsForm = async () => {
    this.setState({
      showEmailDomainsForm: false,
    });
  };

  emailDomainsChanged = async (emailDomains) => {
    const response = await this.updateEmailDomains(
      this.state.registrationCode.id,
      emailDomains
    );

    if (response) {
      const { status, data } = response;

      if (status) {
        const emailDomainsList = data.emailDomains.map((item) => {
          return {
            domain: item,
          };
        });

        const item = this.state.registrationCode;
        item.emailDomains = data.emailDomains;
        item.emailDomainsList = emailDomainsList;

        this.setState({
          updatedEmailDomains: data,
          emailDomainsList,
          registrationCode: item,
        });
      } else {
        this.setState({
          emailDomainsList: this.state.emailDomainsList,
        });
      }
    } else {
      this.setState({
        emailDomainsList: this.state.emailDomainsList,
      });
    }
  };

  updateEmailDomains = async (id, emailDomains) => {
    const url = `/api/registrationCodes/emailDomains/${id}`;

    const data = {
      emailDomains,
    };

    try {

      // RM stringify
      const response = await axios.patch(url, data);

      if (response.status === 200 || response.status === 400) {
        const { status, msg, data } = response.data;

        this.setState({
          statusError: !status,
          statusText: msg,
        });

        return {
          status,
          data,
        };
      }
    } catch (err) {
      console.log(err);

      const msg = err.response?.data?.errMsg;

      this.setState({
        statusError: true,
        statusText: msg ? msg : `Error updating email domains: ${err}`,
      });
    }

    return null;
  };

  render() {
    return (
      <div className="pageWrapper">
        <Modal show={this.state.showForm} onHide={this.closeForm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.registrationCodeTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RegistrationCodeUI
              data={this.state.updatedRegistrationCode}
              partners={this.state.partners}
              dataChanged={this.registrationCodeChanged}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeForm}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={this.saveForm}
              disabled={this.validate(
                this.state.registrationCode,
                this.state.updatedRegistrationCode
              )}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showEmailDomainsForm}
          onHide={this.closeEmailDomainsForm}
        >
          <Modal.Header closeButton>
            <Modal.Title>Email Domains List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container className="p-2">
              <EmailDomainsUI
                emailDomains={this.state.emailDomainsList}
                statusError={this.state.statusError}
                statusText={this.state.statusText}
                emailDomainsChanged={this.emailDomainsChanged}
              />
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeEmailDomainsForm}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div
          style={{
            display: "flex",
            justifyContent: "start",
            padding: "10px",
          }}
        >
          <div
            style={{
              padding: "5px 10px 0 0",
              marginLeft: "10px",
              fontSize: "15px",
            }}
          >
            Status:
          </div>
          <Dropdown>
            <Dropdown.Toggle variant="success" size="lg" id="statusDropdown">
              {this.state.expirationStatus.title}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {EXPIRATION_STATUS &&
                EXPIRATION_STATUS.map((item) => (
                  <Dropdown.Item
                    key={item.active}
                    as="button"
                    style={{
                      fontSize: "14px",
                    }}
                    onClick={() => this.expirationStatusChanged(item)}
                  >
                    {item.title}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>

          <button
            className="export btn btn-primary ml-2 mr-2"
            style={{ width: "200px" }}
            onClick={() => this.openForm(null)}
            disabled={!this.state.partnersLoaded}
          >
            Add new Registation Code
          </button>

          <div
            style={{
              color: this.state.statusError ? "red" : "green",
              padding: "5px 10px 0 0",
              marginLeft: "10px",
              fontSize: "15px",
            }}
          >
            {this.state.statusText}
          </div>
        </div>
        <div
          style={{
            height: "calc(100% - 25px)",
            clear: "both",
          }}
        >
          <div
            style={{
              height: "75vh",
              width: "100%",
              "font-size": "14px",
            }}
            className="ag-theme-balham"
          >
            <AgGridReact
              modules={this.state.modules}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              frameworkComponents={this.state.frameworkComponents}
              overlayLoadingTemplate={this.state.overlayLoadingTemplate}
              overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
              onGridReady={this.onGridReady}
              rowHeight={this.state.rowHeight}
              headerHeight={this.state.headerHeight}
              rowData={this.state.rowData}
              domLayout={this.state.domLayout}
              suppressPaginationPanel={true}
            />
          </div>
        </div>
        {this.state.loading && <div className="loader6"></div>}
      </div>
    );
  }
}

export default RegistrationCodesConfig;
